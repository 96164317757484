@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
:root {
  --preto: #494949;
  --roxo: #7b0ed8;
  --cyano: #00c5ae;
  --verde: #0d5249;
  --verdeBtn: #1aa385;
  --amerelo: #ffad1b;
  --laranja: #ff8900;
  --cinza-claro: #dbdbdb;
  --branco-lista: #f4f4f4;
  --border-color: #bfbfbf;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  -moz-appearance: initial;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 22px;
  max-width: 100vw;
  height: 100vh;
  color: #494949;
  color: var(--preto);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: min(90vw, 500px);
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* box-shadow: 10px 15px 20px 0px rgba(0, 0, 0, 0.2); */
}

/* Mensagem de sucesso e erro ##############################*/

.mensagem {
  width: 90%;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  margin: 1.5rem auto;
  display: block;
  background-color: #1aa385;
  background-color: var(--verdeBtn);
}

/* Card #######################################################3*/

.card .logo {
  max-width: 80%;
  display: block;
}

.card form {
  width: 90%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card form label {
  display: inline-block;
  width: 100%;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}

.userMessage {
  font-size: 0.9rem;
  font-weight: 400;
  color: crimson;
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.1rem;
}

.card form input {
  padding: 0.7rem 1.5rem;
  width: 100%;
  border: 1px solid #dbdbdb;
  border: 1px solid var(--cinza-claro);
  border-radius: 4px;
  font-size: 1rem;
  border: none;
}

.input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  border: 1px solid var(--cinza-claro);
  border-radius: 4px;
  font-size: 1rem;
  padding-right: 0.8rem;
  cursor: pointer;
}

.input:hover {
  border-color: #1aa385;
  border-color: var(--verdeBtn);
}

form input:hover,
form input:focus {
  outline: none;
}

.card form button {
  padding: 1rem 1.5rem;
  font-weight: bolder;
  font-size: 1rem;
  color: #f4f4f4;
  color: var(--branco-lista);
  background-color: #ffad1b;
  background-color: var(--amerelo);
  border-radius: 5px;
  margin-top: 1.5rem;
  transition: 0.3s;
  cursor: pointer;
}

.card form button:disabled {
  opacity: 0.5;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(0, 197, 174);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0, 197, 174) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

